<ng-template *ngIf="propertyViewModel?.securityAccess == null; then baseComponent else securityComponent"></ng-template>

<ng-template #baseComponent>
    <nts-base-enum-text-box #baseEnumTextBox
        (onChange)="onChange($event)"
        (onFinishEditing)="onFinishEditing.emit($event)"
        (onBlur)="onBlur()"
        [value]="value"
        [listenClickOutside]="listenClickOutside"
        [options]="propertyViewModel.visibleValueDescriptions"
        [errorList]="propertyViewModel?.errors$ | async"
        [appendTo]="appendTo"
        [showErrorTooltip]="showErrorTooltip"
        [showErrorBorder]="showErrorBorder"
        [isNullable]="isNullable"
        [isDisabled]="isDisabled || !propertyViewModel.isEnabled || propertyViewModel?.securityAccess != null"
        [placeholder]="placeholder"
        [scrollElementClass]="scrollElementClass"
        [customCommandList]="customCommandsName"
        [title]="getTitle()"
        optionLabel="description"
        dataKey="key">
    </nts-base-enum-text-box>
</ng-template>

<ng-template #securityComponent>
    <nts-base-security-text-box #baseSecurityTextBox [accessMode]="propertyViewModel.securityAccess"
        [value]="initialValue != null ? initialValue : propertyViewModel.formattedValue"></nts-base-security-text-box>
</ng-template>
