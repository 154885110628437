import { PropertyViewModel } from '../property-view-model';
import { PropertyViewModelInitializationInfo } from '../property-view-model-initialization-info';
import { EnumMetaData, EnumResource } from '../../meta-data';
import { MessageResourceManager } from '../../resources';

export interface EnumItemInterface<TKey = number> {

    key: TKey;
    description: string;
    descriptionExt: string;
    disabled?: boolean;
    isDefault?: boolean;
}

export class EnumItem<TKey = number> implements EnumItemInterface<TKey>{

    disabled: boolean;

    get isVisible(): boolean {
        return this._isVisible;
    }
    set isVisible(isVisible) {
        this._isVisible = isVisible;
    }

    get key(): TKey {
        return this._key;
    }

    get description(): string {
        return this._description;
    }

    get descriptionExt(): string {
        return this._descriptionExt;
    }

    private _key: TKey;
    private _description: string;
    private _descriptionExt: string;
    private _isVisible: boolean;

    constructor(
        val: TKey,
        description: string,
        descriptionExt?: string,
        isVisible = true
    ) {
        this._key = val;
        this._description = description;
        this._descriptionExt = descriptionExt;
        this._isVisible = isVisible;
    }
}

export class EnumPropertyViewModelItem<TKey = number> extends EnumItem<TKey> {

    private internalIsVisible = true
    override get isVisible(): boolean {
        return this.internalIsVisible;
    }
    override set isVisible(isVisible: boolean) {
        this.internalIsVisible = isVisible;
        if (this.parent) {
            this.parent.updateVisibleValueDescriptions();
        }
    }

    constructor(
        val: TKey,
        description: string,
        descriptionExt?: string,
        isVisible?: boolean,
        private readonly parent?: BaseEnumPropertyViewModel<TKey>
    ) {
        super(val, description, descriptionExt, isVisible);
    }
}

export abstract class BaseEnumPropertyViewModel<T = number> extends PropertyViewModel<T>  {

    metadataMinValue: number;
    metadataMaxValue: number;
    precision: number;
    private internalVisibleValueDescriptions: Array<EnumPropertyViewModelItem<T>> = [];

    get visibleValueDescriptions(): Array<EnumPropertyViewModelItem<T>> {
        return this.internalVisibleValueDescriptions;
    }

    get currentItem(): T {
        return this.value;
    }
    set currentItem(val: T) {
        if (this.value !== val) {
            this.value = val;
        }
    }

    private internalValueDescriptions:Array<EnumPropertyViewModelItem<T>> = [];

    get valueDescriptions(): Array<EnumPropertyViewModelItem<T>>{
        return this.internalValueDescriptions;
    }

    set valueDescriptions(val: Array<EnumPropertyViewModelItem<T>>){
        this.internalValueDescriptions = val;
        this.onPropertyChanged('valueDescriptions');
        this.updateVisibleValueDescriptions();

    }

    constructor(initInfo: PropertyViewModelInitializationInfo, valueIsNullable: boolean, sort?: boolean) {
        super(initInfo, valueIsNullable);

        const valuesResource = (initInfo.propertyMetaData as EnumMetaData<T>).valuesResource;

        if (valuesResource) {
            valuesResource.forEach((value: EnumResource<T>) => {

                value.displayValue = MessageResourceManager.Current.getMessage(value.resourceKey);
                value.displayExtValue = MessageResourceManager.Current.getMessageNoFaultback(value.descriptionExtKey);

                // Considera solo le chiavi numeriche (evita di visualizzare la chiave $type)
                this.internalValueDescriptions.push(
                    new EnumPropertyViewModelItem<T>(
                        value.enumValue,
                        value.displayValue,
                        value.displayExtValue,
                        true,
                        this
                    )
                );
            });
            this.updateVisibleValueDescriptions();

        }
    }

    getFormattedValue(updatedValue: T) {
        const value = this.valueDescriptions.find(vd => vd.key === updatedValue)?.description;
        return value ?? MessageResourceManager.Current.getMessage('EnumFormattedValue_NotSet');
    }

    sort() {
        this.valueDescriptions.sort((x, y) => x.description.localeCompare(y.description));
    }

    setDefaultValueFromLayoutMetaData(value: string) {
        //TODO

    }

    updateVisibleValueDescriptions() {
        this.internalVisibleValueDescriptions = this.valueDescriptions.filter((v) => v.isVisible);
        this.onPropertyChanged('visibleValueDescriptions');
    }
}

export class EnumPropertyViewModel<T = number> extends BaseEnumPropertyViewModel<T> {

    constructor(initInfo: PropertyViewModelInitializationInfo) {
        super(initInfo, false);
    }

    override async resetValue(useDefaultValue: boolean): Promise<void> {
        if (useDefaultValue) {
            await this.setValueAsync(this.valueDescriptions.length > 0 ? this.valueDescriptions[0].key : this._defaultFaultBackValue);
        } else {
            await this.setValueAsync(this._defaultFaultBackValue);
        }
    }
}

export class StringEnumPropertyViewModel extends EnumPropertyViewModel<string> {
    constructor(initInfo: PropertyViewModelInitializationInfo) {
        super(initInfo);
        this._defaultFaultBackValue = '';
    }
}

export class NumberEnumPropertyViewModel extends EnumPropertyViewModel<number> {
    constructor(initInfo: PropertyViewModelInitializationInfo) {
        super(initInfo);
        this._defaultFaultBackValue = 0;
    }
}
export class NEnumPropertyViewModel<T = number> extends BaseEnumPropertyViewModel<T> {

    constructor(initInfo: PropertyViewModelInitializationInfo) {
        super(initInfo, true);
        this._defaultFaultBackValue = null;
    }

    override async resetValue(useDefaultValue: boolean): Promise<void> {
        await this.setValueAsync(useDefaultValue ? null : this._defaultFaultBackValue);
    }
}

export class NNumberEnumPropertyViewModel extends NEnumPropertyViewModel<number> {

}

export class NStringEnumPropertyViewModel extends NEnumPropertyViewModel<string> {

}
