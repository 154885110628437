<div class="main-container">
    <div class="layout-commandbar outside-layout-control">
        <div class="layout-gutter-left"></div>
        <div class="layout-content outside-layout-control-content">
            <div class="horizontal-container">

                <div class="root-container">
                    <div class="inline-command-bar command-bar" role="menubar" data-command-bar="true">

                        <div role="presentation" class="action-list-control-container">

                            <div class="horizontal-flex-container">


                                <ng-template ngFor let-commandGroup [ngForOf]="commandsGroup" let-i="index">

                                    <!-- Separator -->
                                    <div class="navigation-bar-row-separator only-desktop" *ngIf="i > 0">
                                    </div>
                                    <!-- Separator -->

                                    <ng-template ngFor let-command [ngForTrackBy]="trackById"
                                        [ngForOf]="commandGroup.commands">

                                        <div class="horizontal-flex-container-item-layout only-desktop"
                                            *ngIf="!command.onlyInMoreOptions && (command.isVisible$ | async)">
                                            <div class="command-bar-button-container">

                                                <nts-ribbon-button
                                                    [title]="command.tooltip$ | async"
                                                    [tabIndex]="0"
                                                    [isLoading]="(command.loading$ | async)"
                                                    [commands]="command.children"
                                                    [label]="(command.showDisplayName$ | async) ? (command.displayName$ | async) : ''"
                                                    [icon]="command.iconClass$ | async"
                                                    [isHightlighted]="command.isHighlighted(null) | async"
                                                    [isDisabled]="!(command.canExecute$ | async)"
                                                    (onClicked)="executeCommand(command, $event)"></nts-ribbon-button>

                                            </div>

                                        </div>
                                    </ng-template>

                                </ng-template>


                                <div class="sticky-area" [hidden]="(!mobileMenuCommand && !moreOptionsCommand) || ( (mobileMenuCommand && !(mobileMenuCommand.isVisible$ | async)) && (moreOptionsCommand && !(moreOptionsCommand.isVisible$ | async)))">

                                    <!-- Begin Mobile Menu -->
                                    <div class="horizontal-flex-container-item-layout extended-layout only-mobile" *ngIf="mobileMenuCommand?.isVisible$ | async">

                                        <div class="command-bar-button-container">
                                            <nts-ribbon-button
                                            #mobileMenuButton
                                            [title]="mobileMenuCommand.tooltip$ | async"
                                            [label]="(mobileMenuCommand.showDisplayName$ | async) ? (mobileMenuCommand.displayName$ | async) : ''"
                                            [isLoading]="mobileMenuCommand.loading$ | async"
                                            [icon]="mobileMenuCommand.iconClass$ | async"
                                            *ngIf="mobileMenuCommand"
                                            (onClicked)="mobileMenuClick(mobileMenu, $event, mobileMenuButton)"></nts-ribbon-button>
                                        </div>

                                        <p-slideMenu [backLabel]="'std_Menu_Back' | NTSTranslate" [menuWidth]="mobileMenuViewportWidth$ | async" styleClass="mobile-menu" #mobileMenu
                                            [viewportHeight]="mobileMenuViewportHeight$ | async" [model]="mobileMenuItemList$ | async" [popup]="true" appendTo="body">
                                        </p-slideMenu>
                                    </div>
                                    <!-- End Mobile Menu -->

                                    <!-- Separator for MoreOptions -->
                                    <div class="navigation-bar-row-separator only-desktop" *ngIf="commandsGroup?.length > 0 && moreOptionsCommand?.isVisible$ | async">
                                    </div>
                                    <!-- End Separator for MoreOptions -->

                                    <!-- Begin More -->
                                    <div class="horizontal-flex-container-item-layout extended-layout only-desktop" [hidden]="!moreOptionsCommand || !(moreOptionsCommand.isVisible$ | async)">

                                        <div class="command-bar-button-container">
                                            <nts-ribbon-button
                                            #moreOptionMenuButton
                                            [title]="moreOptionsCommand.tooltip$ | async"
                                            [label]="(moreOptionsCommand.showDisplayName$ | async) ? (moreOptionsCommand.displayName$ | async) : ''"
                                            [icon]="moreOptionsCommand.iconClass$ | async"
                                            [isLoading]="moreOptionsCommand.loading$ | async"
                                            *ngIf="moreOptionsCommand"
                                            (onClicked)="moreOptionClick(moreOptionsMenu, $event, moreOptionMenuButton)"></nts-ribbon-button>
                                        </div>

                                        <p-slideMenu [backLabel]="'std_Menu_Back' | NTSTranslate" [menuWidth]="250" styleClass="more-options-menu" #moreOptionsMenu
                                            [viewportHeight]="moreOptionMenuViewportHeight$ | async" [model]="moreOptionsMenuItemList$ | async" [popup]="true" appendTo="body">
                                        </p-slideMenu>
                                    </div>
                                    <!-- Fine More -->
                                    <div class="default-actions-container" *ngIf="defaultCommand && (defaultCommand.isVisible$ | async)">

                                        <nts-filled-button
                                            [title]="defaultCommand.tooltip$ | async"
                                            [label]="(defaultCommand.showDisplayName$ | async) ? (defaultCommand.displayName$ | async) : ''"
                                            [icon]="defaultCommand.iconClass$ | async"
                                            [isLoading]="defaultCommand.loading$ | async"
                                            [type]="defaultCommandType"
                                            [isDisabled]="!(defaultCommand.canExecute$ | async)"
                                            (onClicked)="executeCommand(defaultCommand, $event)"></nts-filled-button>

                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="layout-gutter-right"></div>
    </div>
</div>
