import { EnumItemInterface } from './../../../../../view-models/base-type/enum-property-view-model';
import { AfterViewInit, ElementRef, EventEmitter, NgZone, Output, Renderer2 } from '@angular/core';
import { ChangeDetectionStrategy, OnChanges, OnDestroy, Component, Input, ViewChild, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { Dropdown, DropdownModule } from 'primeng/dropdown';
import { NgxPopperjsDirective, NgxPopperjsModule, NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs';
import { FormsModule } from '@angular/forms';
import { AsyncPipe, NgClass, NgFor } from '@angular/common';
import { PopperHelper } from '@nts/std/src/lib/utility';
import { BehaviorSubject, filter, fromEvent, takeUntil, throttleTime } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'nts-base-enum-text-box',
    templateUrl: './base-enum-text-box.component.html',
    styleUrls: ['./base-enum-text-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        DropdownModule,
        FormsModule,
        NgxPopperjsModule,
        NgClass,
        AsyncPipe,
        NgFor
    ]
})
export class BaseEnumTextBoxComponent implements OnChanges, OnDestroy, AfterViewInit {

    @Input() tabIndex = -1;
    @Input() isDisabled = false;
    @Input() isReadonly = false;
    @Input() value: EnumItemInterface<number|string>;
    @Input() options: Array<EnumItemInterface<number|string>>;
    @Input() customClasses = '';
    @Input() errorList: string[] = [];
    @Input() showErrorTooltip = true;
    @Input() showErrorBorder = true;
    @Input() hasError = false;
    @Input() labelText: string;
    @Input() appendTo: string | HTMLDivElement = null;
    @Input() placeholder = '';
    @Input() title: string;
    @Input() customCommandList = [];
    @Input() scrollElementClass = 'layout-main scrollable-content'
    @Input() dataKey = 'key';
    @Input() optionLabel = 'description';
    @Input() panelClass = 'dropdown-panel-bottom';
    @Input() isNullable = false;
    @Input() listenClickOutside = false;
    @Input() primaryColor = null;

    @Output() onFinishEditing = new EventEmitter();
    @Output() onChange = new EventEmitter();
    @Output() valueChange = new EventEmitter<EnumItemInterface<any>>();
    @Output() onBlur = new EventEmitter();
    @Output() onFocus = new EventEmitter();

    @ViewChild('combo', { static: false }) combo: Dropdown;
    @ViewChild('popperError', { static: false }) popperError: NgxPopperjsDirective;
    @ViewChild('popperInfo', { static: false }) popperInfo: NgxPopperjsDirective;

    documentClickListener: any;
    ngxPopperjsTriggers = NgxPopperjsTriggers;
    ngxPopperjsPlacements = NgxPopperjsPlacements;
    isDropdownOpen$ = new BehaviorSubject<boolean>(false);

    constructor(
        public readonly el: ElementRef,
        private readonly renderer: Renderer2,
        private readonly zone: NgZone,
        private readonly cd: ChangeDetectorRef,
    ) {}

    ngAfterViewInit(): void {
        if (this.primaryColor) {
            // ng-select-container
        }
    }

    ngOnInit() {
        if (this.listenClickOutside) {
            this.bindDocumentClickListener();
        }
    }

    ngOnDestroy(): void {
        this.unbindDocumentClickListener();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['value']) {
            if (!this.isNullable && changes['value']?.currentValue == null) {
                changes['value'].currentValue = this.options[0];
                this.value = changes['value'].currentValue;
                this.cd.detectChanges();
                this.valueChange.emit(this.value);
                this.onChange.emit({ value: this.value });
            }
        }

        if (changes['errorList']) {
            this.checkPopper();
        }
    }

    dropdownChange(e) {
        this.valueChange.emit(e.value);
        this.onChange.emit(e);
    }

    onShow($event): void {
        this.isDropdownOpen$.next(true);

        this.panelClass = $event.element.getBoundingClientRect().top < this.combo.el.nativeElement.getBoundingClientRect().top ? 'dropdown-panel-bottom' : 'dropdown-panel-top';


        fromEvent(document.getElementsByClassName(this.scrollElementClass), 'scroll').pipe(
            untilDestroyed(this),
            takeUntil(this.isDropdownOpen$.pipe(filter((isDropdownOpen) => isDropdownOpen === false))),
            throttleTime(1000)
        ).subscribe((e: Event) =>  {
            // this.focus(null);
            this.hide();
            this.onFinishEditing.emit()
        });

    }

    onHide(e) {
      this.isDropdownOpen$.next(false);
    }

    show() {
        this.combo.show();
    }

    hide() {
        this.combo.hide();
    }

    blur($event){
        this.popperError?.hide();
        this.popperInfo?.hide();
        this.onBlur.emit($event)
    }
    private bindDocumentClickListener() {
        if (!this.documentClickListener) {
            this.zone.runOutsideAngular(() => {
                const documentTarget: any = this.el ? this.el.nativeElement.ownerDocument : 'document';

                this.documentClickListener = this.renderer.listen(documentTarget, 'click', (event) => {
                    if (this.isOutsideClicked(event)) {
                        this.zone.run(() => {
                            this.onFinishEditing.emit();

                            this.cd.markForCheck();
                        });
                    }

                });
            });
        }
    }

    private unbindDocumentClickListener() {
        if (this.documentClickListener) {
            this.documentClickListener();
            this.documentClickListener = null;
        }
    }

    private isOutsideClicked(event: Event) {
        return !(this.el.nativeElement.isSameNode(event.target) || this.el.nativeElement.contains(event.target) || this.isDropDownClicked(event));
    }

    private isDropDownClicked(event: Event) {
        return this.combo ? this.combo.el.nativeElement.contains(event.target) || this.combo.overlayViewChild?.el?.nativeElement?.contains(event.target as any) : false;
    }

    private checkPopper() {
        if (this.popperError && (this.errorList == null || this.errorList.length === 0)) {
            PopperHelper.hide(this.popperError);
        }
        if (this.popperInfo && this.errorList?.length > 0) {
            PopperHelper.hide(this.popperInfo);
        }
    }
}
