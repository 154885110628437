import { firstValueFrom, Observable, of } from 'rxjs';
import { LongOpOrchestratorViewModelInterface } from './long-op-orchestrator-view-model.interface';
import { LongOpToolBarViewModel } from './long-op-tool-bar-view-model';
import { LogService, StatusMessageInterface } from '@nts/std/src/lib/utility';
import { LongOpApiClient } from '../../api-clients/long-op/long-op-api-client';
import { BaseLongOpModel } from '../../domain-models/base-long-op-model';
import { BaseIdentity } from '../../domain-models/base-identity';
import { CoreModel } from '../../domain-models/core-model';
import { CoreOrchestratorViewModel, MsgClearMode } from '../core-orchestrator-view-model';
import { MessageContainer } from '../message-container';
import { ToolBarViewModelInterface } from '../tool-bar-view-model.interface';
import { CommandTypes } from '../commands/command-types';
import { CreateResponse } from '../../responses/create-response';
import { GenericServiceResponse } from '../../responses/generic-service-response';
import { RoutingService } from '../../routing/routing.service';
import { ServiceResponse } from '../../responses/service-response';
import { UIStarter } from '../../starter/ui-starter';
import { BaseError } from '../../messages/base-error';
import { MessageResourceManager } from '../../resources/message-resource-manager';
import { MessageCodes } from '../../resources/message-codes';
import { classToPlain, ClassType, plainToClass } from '../../serialization/class-transformer';
import { GenericServiceRequest } from '../../requests/generic-service-request';
import { ServiceRequest } from '../../requests/service-request';
import { ViewModelStates } from '../states/view-model-states';
import { ClassAdditionalInfo } from '@nts/std/src/lib/utility';
import { InternalViewModel } from '../internal-view-model';
import { LongOpRootViewModel } from './long-op-root-view-model';

export class LongOpOrchestratorViewModel<
    TViewModel extends LongOpRootViewModel<TModel, TIdentity, TParams, TResult, TParamsModel, TResultModel>,
    TApiClient extends LongOpApiClient<TModel, TIdentity, TParamsModel, TResultModel>,
    TModel extends BaseLongOpModel<TIdentity, TParamsModel, TResultModel>,
    TIdentity extends BaseIdentity,
    TParams extends InternalViewModel<TParamsModel, TIdentity>,
    TResult extends InternalViewModel<TResultModel, TIdentity>,
    TParamsModel extends CoreModel<TIdentity>,
    TResultModel extends CoreModel<TIdentity>>
    extends CoreOrchestratorViewModel<TViewModel, TApiClient, TModel, TIdentity>
    implements LongOpOrchestratorViewModelInterface {

    classAdditionalInfo?: ClassAdditionalInfo.LongOp;
    jsonRouteParam: string;

    override showCurrentState = false;

    getToolBarMenu(): ToolBarViewModelInterface {
        return new LongOpToolBarViewModel(this);
    }

    override removeError(item: MessageContainer) {

    }

    override async checkIfPendingChanges(): Promise<boolean> {
        // #3929 disabilito il controllo dello stato del documento quando è una longop
        return false;
    }

    override async checkStatus(): Promise<StatusMessageInterface> {
        const status = await super.checkStatus();
        status.pendingChanges = await this.checkIfPendingChanges();
        return status;
    }

    override checkIfCanUnload(): boolean {
        // #3929 disabilito il controllo dello stato del documento quando è una longop
        return true;
    }

    async create(force?: boolean): Promise<ServiceResponse> {
        let response = new ServiceResponse();
        response.operationSuccedeed = false;
        const result = force || await this.confirmLosingUnsavedDataAsync();
        if (result) {
            try {
                if (this.actionInProgress == false) {
                    this.eventDispatcher.onActionInProgress.next(true);
                }
                response = await this.createWithClearAsync(true);
                LogService.debug(MessageResourceManager.Current.getMessage('CreateLongOpCompleted'));
            } catch (error) {
                LogService.warn(MessageResourceManager.Current.getMessage('CreateLongOpFailed'), error);
            } finally {
                this.eventDispatcher.onActionInProgress.next(false);
            }
        }
        return response;
    }

    async createRootEntityAsync(): Promise<CreateResponse<TModel, TIdentity>> {
        return await firstValueFrom(this.apiClient.create());
    }

    async executeLongOp(): Promise<GenericServiceResponse<TResultModel>> {
        let response = null;
        try {
            if (this.actionInProgress == false) {
                this.eventDispatcher.onActionInProgress.next(true);
            }
            response = await this.executeLongOpImplementationAsync(true);
            LogService.debug(MessageResourceManager.Current.getMessage('ExecuteLongOpCompleted'));
        } catch (error) {
            LogService.error(MessageResourceManager.Current.getMessage('ExecuteLongOpFailed'), error);
        } finally {
            this.eventDispatcher.onActionInProgress.next(false);
        }
        return response;
    }

    async getByJsonObject(jsonObject: string): Promise<ServiceResponse> {
        // di default considera il JsonObject una identity
        const object = plainToClass<TIdentity, TIdentity>(
        this.identityType as ClassType<TIdentity>, JSON.parse(jsonObject) as TIdentity);
        return await this.getByObject<TIdentity>(object);
    }

    async getByObject<TObject extends any>(object: TObject): Promise<ServiceResponse> {
        let response = new ServiceResponse();
        response.operationSuccedeed = false;
        if (await firstValueFrom(this.currentState.canGetByObject())) {
            try {
                if (this.actionInProgress == false) {
                    this.eventDispatcher.onActionInProgress.next(true);
                }
                response = await this.getByObjectImplementationAsync<TObject>(object);
            } catch (e) {
                LogService.warn('getByObject failed', e);
            } finally {
                this.eventDispatcher.onActionInProgress.next(false);
            }
            return response;
        } else {
            const error = new BaseError();
            error.code = 'NotAllowed';
            error.description = MessageResourceManager.Current.getMessageWithStrings(MessageCodes.CommandNotAllowed,
                MessageResourceManager.Current.getMessage('std_CMD_' + CommandTypes[CommandTypes.GetByObject]));
            response.errors = [error];
            this.notAllowedAction(CommandTypes.GetByObject);
            return response;
        }
    }

    updateJsonRouteParam(object = null) {
        let jsonRouteParam: string = null
        if (object) {
            const plainRouteParam: Record<string, any> = classToPlain(object, { strategy: 'excludeAll' });
            jsonRouteParam = JSON.stringify(plainRouteParam);
        }
        this.jsonRouteParam = jsonRouteParam;
    }

    async loadByObjectImplementationAsync<TObject extends any>(object: TObject): Promise<GenericServiceResponse<TModel>> {

        // TODO Tommy gestione rootViewModel virtuale
        // Crea un rootViewModel vuoto così da renderizzare l'interfaccia mentre viene fatta la chiamata
        // const entityClass = NTSReflection.getClassMetadata('responseDomainModelType', this.apiClient);
        // const newEntity = this.createMockEntity<TEntity>(entityClass, this.metadata);
        // await this.tryRebuildViewModelAsyncWithoutResponse(newEntity, false, true);

        return firstValueFrom(this.getByObjectFromApiClient(object))
            .then(async (response) => {
                if (response.operationSuccedeed && response.result != null) {
                    await this.tryRebuildViewModelAsync(response, response.result, false, true);
                    // TODO vedere se serve
                    // await this.setCurrentStateFromGetByIdentityResponse(response);


                    this.updateJsonRouteParam(object);
                    const params = new URLSearchParams(this.queryParams)
                    UIStarter.updateCurrentRoute(this.metadata.rootFullName, object, undefined, '?' + params.toString());
                    const valid = this.viewModelValidate(false);
                    if (!valid) {
                        this.eventDispatcher.onValidationBarCollapsed.next(false);
                    }

                } else {
                    this.refreshMessagesFromResponse(response, MsgClearMode.ClearOnlyTemporaryMessage);
                }
                return response;
            }).catch(err => {
                LogService.debug('loadByIdentityImplementationAsync long op failed', err);
                const response = new GenericServiceResponse<TModel>(this.apiClient.rootModelType);
                response.operationSuccedeed = false;
                return response;
            });
    }

    // Can Actions
    override canGenerateReport(): Observable<boolean> {
        return of(true);
    }

    canCreate(x: any): Observable<boolean> {
        return this.currentState.canCreate();
    }

    canExecuteLongOp(): Observable<boolean> {
        return of(true);
    }

    // isVisible

    isVisibleExecuteLongOp(): Observable<boolean> {
        return of(true);
    }

    // fare override del metodo per modificare il comportamento della maschera
    // e aprire in modalità lettura passando un oggetto generico
    getJsonObjectFromRouteParam(routeParam: string): string | null {
        return routeParam ? RoutingService.decodeUrl(routeParam) : null;
    }

    protected async createWithClearAsync(clearPreviousMessages): Promise<CreateResponse<TModel, TIdentity>> {
        const createResponse = await this.createImplementationAsync(clearPreviousMessages);
        if (createResponse.operationSuccedeed) {
            // aggiorno la rotta corrente rimuovento l'eventuale identity
            this.updateJsonRouteParam();
            const params = new URLSearchParams(this.queryParams)
            UIStarter.updateCurrentRoute(this.metadata.rootFullName, undefined, undefined, '?' + params.toString());
            // Disabilito la validazione in fase di creazione
            // this.viewModelValidate(false);
        }
        return createResponse;
    }

    protected async preCreate(): Promise<void> {

    }

    protected async postCreate(): Promise<void> {

    }

    protected async createImplementationAsync(clearPreviousMessages: boolean): Promise<CreateResponse<TModel, TIdentity>> {

        let ret = new CreateResponse<TModel, TIdentity>(this.apiClient.rootModelType);
        ret.operationSuccedeed = false;
        if (await firstValueFrom(this.canCreate(null))) {

            // TODO Tommy: crea rootViewModel virtual
            // Crea un rootViewModel vuoto così da renderizzare l'interfaccia mentre viene fatta la chiamata
            // const entityClass = NTSReflection.getClassMetadata('domainModelEntityType', this.apiClient);
            // const newEntity = this.createMockEntity<TEntity>(entityClass, this.metadata);
            // await this.tryRebuildViewModelAsyncWithoutResponse(newEntity, true, true);
            await this.preCreate();
            const response = await this.createRootEntityAsync();
            ret = response;
            if (response.operationSuccedeed && response.result != null) {
                await this.tryRebuildViewModelAsync(response, response.result, true, clearPreviousMessages);
                this.currentState.create();
                await this.postCreate();
            } else {
                LogService.debug('createImplementationAsync failed', response);
                // this.toastMessageService.showToastsFromResponse(response);
            }
        } else {
            const error = new BaseError();
            error.code = 'NotAllowed';
            error.description = MessageResourceManager.Current.getMessageWithStrings(MessageCodes.CommandNotAllowed,
                MessageResourceManager.Current.getMessage('std_CMD_' + CommandTypes[CommandTypes.Create]));
            ret.errors = [error];
            this.notAllowedAction(CommandTypes.Create);
        }
        return ret;
    }

    protected async getByObjectImplementationAsync<TObject extends any>(object: TObject): Promise<ServiceResponse> {
        const response = await this.loadByObjectImplementationAsync<TObject>(object);
        if (!this.getByObjectIsSuccessfully(response)) {
            // se non è stato trovato nulla allora passo allo stato di New. Se però sono
            // già nello stato di new allora non faccio nulla
            if (this.currentState.value !== ViewModelStates.New) {
                return await this.createWithClearAsync(false);
            }
        }
        return response;
    }

    protected getByObjectIsSuccessfully(response: GenericServiceResponse<TModel>) {
        return response.operationSuccedeed && response.result != null;
    }

    protected getByObjectRequest<TObject extends any>(object: TObject): ServiceRequest {
        const request: GenericServiceRequest<TObject> = new GenericServiceRequest<TObject>();
        request.requestData = object;
        return request;
    }

    protected getByObjectResponse(): GenericServiceResponse<TModel> {
        return new GenericServiceResponse<TModel>(this.apiClient.rootModelType);
    }

    protected getByObjectUri(): string {
        return 'GetByObject'
    }

    protected getByObjectFromApiClient<TObject extends any>(object: TObject): Observable<GenericServiceResponse<TModel>> {
        return this.apiClient.getByObject(
            this.getByObjectRequest<TObject>(object),
            this.getByObjectResponse(),
            this.getByObjectUri()
        )
    }

    protected async executeLongOpImplementationAsync(clearPreviousMessages: boolean): Promise<GenericServiceResponse<TResultModel>> {

        // TODO Tommy: crea rootViewModel virtual
        // Crea un rootViewModel vuoto così da renderizzare l'interfaccia mentre viene fatta la chiamata
        // const entityClass = NTSReflection.getClassMetadata('domainModelEntityType', this.apiClient);
        // const newEntity = this.createMockEntity<TEntity>(entityClass, this.metadata);
        // await this.tryRebuildViewModelAsyncWithoutResponse(newEntity, true, true);
        //await this.preExecute();
        const response = await firstValueFrom(this.apiClient.executeLongOp(this.rootViewModel.getDomainModel()));
        this.showFromResponse(response, MsgClearMode.ClearAllMessages);
        if (response.operationSuccedeed && response.result != null) {
            const dm = this.rootViewModel.getDomainModel();
            dm.result = response.result;
            await this.tryRebuildViewModelAsync(response, dm, true, clearPreviousMessages);

            this.currentState.modify();
            //await this.postExecute();
        } else {
            LogService.debug('executeLongOpImplementationAsync failed', response);
        }
        return response;
    }
}
