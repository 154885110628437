import { MessageResourceManager } from './../../../../resources/message-resource-manager';
import { ChangeDetectionStrategy, OnChanges, OnDestroy, Component, Input, ViewChild, ChangeDetectorRef, SimpleChanges, EventEmitter, Output, OnInit } from '@angular/core';
import { BasePropertyComponent } from '../base-property-component';
import { BaseEnumPropertyViewModel, EnumPropertyViewModelItem, NEnumPropertyViewModel } from '../../../../view-models/base-type/enum-property-view-model';
import { HttpClient } from '@angular/common/http';
import { BaseOffsetPropertyViewModel, NOffsetPropertyViewModel } from '../../../../view-models/base-type/offset-property-view-model';
import { filter, takeUntil } from 'rxjs/operators';
import { Observable, merge } from 'rxjs';
import { BaseEnumTextBoxComponent } from '../base/base-enum-text-box/base-enum-text-box.component';
import { BaseSecurityTextBoxComponent } from '../base/base-security-text-box/base-security-text-box.component';
import { PopperHelper } from '@nts/std/src/lib/utility';
import { AsyncPipe, NgIf } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'nts-enum-text-box',
    templateUrl: './enum-text-box.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./enum-text-box.component.scss'],
    standalone: true,
    imports: [
        BaseEnumTextBoxComponent,
        NgIf,
        BaseSecurityTextBoxComponent,
        AsyncPipe
    ]
})
export class EnumTextBoxComponent extends BasePropertyComponent<number> implements OnChanges, OnInit {

    @Input() override propertyViewModel: BaseEnumPropertyViewModel;
    @Input() isDisabled = false;
    @Input() appendTo: string | HTMLDivElement = null;
    @Input() showErrorTooltip = true;
    @Input() showErrorBorder = true;
    @Input() customCommandsName = [];
    @Input() listenClickOutside = false;
    @Input() tabIndex: number;
    @Input() primaryColor = null;
    @Input() scrollElementClass = 'layout-main scrollable-content'

    @Output() onFinishEditing = new EventEmitter();

    @ViewChild('baseEnumTextBox', { static: false }) baseEnumTextBox: BaseEnumTextBoxComponent;
    @ViewChild('baseSecurityTextBox', { static: false }) baseSecurityTextBox: BaseSecurityTextBoxComponent;

    value: EnumPropertyViewModelItem;

    get placeholder(): string {
        return MessageResourceManager.Current.getMessage('std_Enum_Nullable_DisplayName');
    }

    get input(): HTMLInputElement {
        return (this.propertyViewModel?.securityAccess == null ? this.baseEnumTextBox.combo.el.nativeElement.children[0].children[0].children[0] : this.baseSecurityTextBox.securityTextBox.nativeElement);
    }

    get isNullable() {
        return this.propertyViewModel instanceof NEnumPropertyViewModel || this.propertyViewModel instanceof NOffsetPropertyViewModel;
    }

    constructor(private readonly http: HttpClient, cd: ChangeDetectorRef) {
        super(cd);
    }

    untilDestroyedThis(): <U>(source: Observable<U>) => Observable<U> {
        return untilDestroyed<this>(this)
    }

    ngOnInit(): void {
        if (!this.propertyViewModel) { throw new Error('Missing viewModel!'); }
    }

    override async ngOnChanges(changes: SimpleChanges): Promise<void> {

        if (changes && changes['propertyViewModel'] && changes['propertyViewModel'].currentValue) {
            this.destroyOnChange$.next(true);

             // offset
            if (this.propertyViewModel instanceof BaseOffsetPropertyViewModel) {
                await this.propertyViewModel.populateValueDescriptionsAsync(this.http);
            }

            this.initCombo();

            // Rimane in ascolto quando cambia il pvm e il valore
            merge(
                this.propertyViewModel.customGetterValueChanged,
                this.propertyViewModel.propertyViewModelChanged,
                this.propertyViewModel.modelChanged,
                this.propertyViewModel.propertyChanged.pipe(filter((args) => args.propertyName === this.propertyViewModel.bindedValuePropertyName))
            ).pipe(
                this.untilDestroyedThis(),
                takeUntil(this.destroyOnChange$)
            ).subscribe(() => {
                this.initCombo();
                this.cd.detectChanges();
            });

            // Rimane in ascolto quando cambiano le property diverse dal valore: isVisible, isEnabled, ecc
            this.propertyViewModel.propertyChanged.pipe(
                this.untilDestroyedThis(),
                takeUntil(this.destroyOnChange$),
                filter((args) => args.propertyName !== this.propertyViewModel.bindedValuePropertyName)
            ).subscribe(() => {
                this.cd.detectChanges();
            });

            this.propertyViewModel.onFocusRequested.pipe(
                this.untilDestroyedThis(),
                takeUntil(this.destroyOnChange$)
            ).subscribe(() => {
                this.focus(this.propertyViewModel.hasErrors);
                this.input.select();
            });

        }
    }

    getTitle(): string {
        if (this.propertyViewModel.value != null && this.propertyViewModel.valueDescriptions != null) {
            const value = this.propertyViewModel.valueDescriptions.find(vd => vd.key === this.propertyViewModel.value);
            return value && value.description ? value.description : this.placeholder;
        } else {
            return this.placeholder;
        }
    }

    private initCombo(): void {
        this.value = this.propertyViewModel.valueDescriptions.find(x => x.key === this.propertyViewModel.value) ?? null;
    }

    async onBlur(): Promise<void> {
        if (!this.isDisabled && this.propertyViewModel.isEnabled) {
            if (this.value == null) {
                this.valueChange.emit(null);
                if (this.modelUpdate) {
                    await this.propertyViewModel.resetValue(false);
                }
            } else {
                this.valueChange.emit(this.value.key);
                if (this.modelUpdate) {
                    await this.propertyViewModel.setValueAsync(this.value.key);
                    // this.value = this.propertyViewModel.valueDescriptions.find(x => x.key === this.propertyViewModel.value);
                }
            }
        }
    }

    onChange(event): void {
        this.value = event.value;
        this.valueChange.emit(this.value != null ? this.value.key : null);
        if (this.modelUpdate) {
            this.propertyViewModel.value = this.value != null ? this.value.key : null;
        }
    }

    showErroTooltip(): void {
        if (this.baseEnumTextBox?.popperError) {
            PopperHelper.show(this.baseEnumTextBox?.popperError)
        }
    }

    show() {
      this.baseEnumTextBox.show();
    }

    hide() {
      this.baseEnumTextBox.hide();
    }
}
