import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { StdModule, NtsAppRootModule } from '@nts/std';
import { environment } from 'src/environments/environment';
import { environment as devEnvironment } from 'src/environments/environment.dev';
import { FormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NtsBootService } from '@nts/std';
import { EnvironmentConfiguration, UNPROXIED_ENVIRONMENT, unproxiedEnvironmentFactory } from '@nts/std/src/lib/environments';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    StdModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    // DxTileViewModule,
    // DxButtonModule,
    // DxListModule,
    // DxLookupModule,
    // DxScrollViewModule,
    // DxSortableModule,
    // DxFormModule,
    // DxCheckBoxModule,
    // DxToolbarModule,
    // DxTextBoxModule,
    // DxDropDownButtonModule,
    // DxValidatorModule,
    // DxSelectBoxModule,
    // DxValidationSummaryModule,
    // DxDataGridModule,
    // DxDataGridModule,
    // DxToolbarModule,
    // DxButtonGroupModule,
    // DxTemplateModule,
    // DxFilterBuilderModule
  ],
  providers: [
    {
      provide: EnvironmentConfiguration,
      useValue: { ...environment }
    },
    {
      provide: UNPROXIED_ENVIRONMENT,
      useFactory: unproxiedEnvironmentFactory(devEnvironment),
      deps: [EnvironmentConfiguration]
    },
    ...StdModule.getRootProviders(),
  ],
  bootstrap: [AppComponent]
})
export class AppModule extends NtsAppRootModule {
  constructor(
    bootService: NtsBootService
  ) {
    super(bootService);

    const themesList = [
      "light",
      "dark",
      "carmine",
      "greenmist",
    ];

    themesList.forEach((e, i) => {
      const dx = document.createElement('link');
      dx.href = e + '.css';
      dx.rel = 'dx-theme';
      dx.setAttribute("data-theme", "generic." + e);
      dx.setAttribute("data-active", i == 0 ? "true" : "false");

      document.head.appendChild(dx);
    })


  }
}
