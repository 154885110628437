import { CheckBoxState } from '../../layout-meta-data/check-box-state.enum';
import { AccessMode } from '../../meta-data/access-mode.enum';
import { MessageResourceManager } from '../../resources/message-resource-manager';
import { PropertyViewModel } from '../property-view-model';
import { PropertyViewModelInitializationInfo } from '../property-view-model-initialization-info';

export abstract class BaseBoolPropertyViewModel extends PropertyViewModel<boolean>  {

    showLabel = false;

    constructor(initInfo: PropertyViewModelInitializationInfo, valueIsNullable: boolean) {
        super(initInfo, valueIsNullable);
    }

    override async resetValue(useDefaultValue: boolean): Promise<void> {
        await this.setValueAsync(useDefaultValue ? false : this._defaultFaultBackValue);
    }

    setDefaultValueFromLayoutMetaData(value: string) {
        if (value === CheckBoxState.True.toString()) {
            this._layoutDefaultValue = true;
        } else if (value === CheckBoxState.False.toString()) {
            this._layoutDefaultValue = false;
        }
    }
}

export class BoolPropertyViewModel extends BaseBoolPropertyViewModel {

    constructor(initInfo: PropertyViewModelInitializationInfo) {
        super(initInfo, false);
        this._defaultFaultBackValue = false;
    }

    getFormattedValue(updatedValue: boolean): string {

        if (this.securityAccess === AccessMode.Deny) {
            return MessageResourceManager.Current.getMessage('BoolFormattedValue_Forbidden');
        }

        return updatedValue ?
          MessageResourceManager.Current.getMessage('BoolFormattedValue_True') :
          MessageResourceManager.Current.getMessage('BoolFormattedValue_False');
    }
}

export class NBoolPropertyViewModel extends BaseBoolPropertyViewModel {

    constructor(initInfo: PropertyViewModelInitializationInfo) {
        super(initInfo, true);
        this._defaultFaultBackValue = null;
    }

    getFormattedValue(updatedValue: boolean): string {
        if (this.securityAccess === AccessMode.Deny) {
            return MessageResourceManager.Current.getMessage('BoolFormattedValue_Forbidden');
        }

        return updatedValue == null ?
        MessageResourceManager.Current.getMessage('BoolFormattedValue_NotSet') :
            (updatedValue ?
              MessageResourceManager.Current.getMessage('BoolFormattedValue_True') :
              MessageResourceManager.Current.getMessage('BoolFormattedValue_False')
            );
    }
}
