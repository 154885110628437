export class MessageCodes {
    static readonly PropertyEmailNotValid = 'std_PropertyEmailNotValid';
    static readonly PropertyEmailNotValid_TAG_NomeCampo = 'std_NomeCampo';

    static readonly PropertyStringLongerThenMaxLength = 'std_PropertyStringLongerThenMaxLength';
    static readonly PropertyStringLongerThenMaxLength_TAG_NomeCampo = 'std_NomeCampo';
    static readonly PropertyStringLongerThenMaxLength_TAG_NumeroCaratteri = 'std_NumeroCaratteri';

    static readonly PropertyCollectionUniqueField = 'std_PropertyCollectionUniqueField';
    static readonly PropertyCollectionUniqueFields = 'std_PropertyCollectionUniqueFields';
    static readonly PropertyCollectionUniqueFields_TAG_NomeCollection = 'std_NomeCollection';
    static readonly PropertyCollectionUniqueFields_TAG_ListaColonne = 'std_ListaColonne';

    static readonly PropertyStringShortedThenMinLength = 'std_PropertyStringShortedThenMinLength';
    static readonly PropertyStringShortedThenMinLength_TAG_NomeCampo = 'std_NomeCampo';
    static readonly PropertyStringShortedThenMinLength_TAG_NumeroCaratteri = 'std_NumeroCaratteri';

    // #region Allowed Chars Validator
    static readonly PropertyStringLowerLettersOnly = 'std_PropertyStringLowerLettersOnly';
    static readonly PropertyStringLowerLettersOnly_TAG_NomeCampo = 'std_NomeCampo';

    static readonly PropertyStringLowerLettersOrNumbersOnly = 'std_PropertyStringLowerLettersOrNumbersOnly';
    static readonly PropertyStringLowerLettersOrNumbersOnly_TAG_NomeCampo = 'std_NomeCampo';

    static readonly PropertyStringNumberOnly = 'std_PropertyStringNumberOnly';
    static readonly PropertyStringNumberOnly_TAG_NomeCampo = 'std_NomeCampo';

    static readonly PropertyStringUpperLettersOnly = 'std_PropertyStringUpperLettersOnly';
    static readonly PropertyStringUpperLettersOnly_TAG_NomeCampo = 'std_NomeCampo';

    static readonly PropertyStringUpperLettersOrNumbersOnly = 'std_PropertyStringUpperLettersOrNumbersOnly';
    static readonly PropertyStringUpperLettersOrNumbersOnly_TAG_NomeCampo = 'std_NomeCampo';
    // #endregion Allowed Chars Validator

    // #region Required Validator
    static readonly PropertyRequired = 'std_PropertyRequired';
    static readonly PropertyRequired_TAG_NomeCampo = 'std_NomeCampo';
    // #endregion Required Validator

    // #region Range Number Validator
    static readonly PropertyMustBeGreaterThen = 'std_PropertyMustBeGreaterThen';
    static readonly PropertyMustBeGreaterThen_TAG_NomeCampo = 'std_NomeCampo';
    static readonly PropertyMustBeGreaterThen_TAG_Valore = 'std_Valore';

    static readonly PropertyMustBeLessThen = 'std_PropertyMustBeLessThen';
    static readonly PropertyMustBeLessThen_TAG_NomeCampo = 'std_NomeCampo';
    static readonly PropertyMustBeLessThen_TAG_Valore = 'std_Valore';

    static readonly PropertyValueNotInRange = 'std_PropertyValueNotInRange'
    static readonly PropertyValueNotInRange_TAG_NomeCampo = 'std_NomeCampo';
    static readonly PropertyValueNotInRange_TAG_MinValue = 'std_minValue';
    static readonly PropertyValueNotInRange_TAG_MaxValue = 'std_maxValue';
    // #endregion Range Number Validator

    // #region Decimal Format Validator
    static readonly PropertyDecimalOverDecimalPrecision = 'std_PropertyDecimalOverDecimalPrecision';
    static readonly PropertyDecimalOverDecimalPrecision_TAG_NomeCampo = 'std_NomeCampo';
    static readonly PropertyDecimalOverDecimalPrecision_TAG_MassimoNumeroDecimali = 'std_MassimoNumeroDecimali';

    static readonly PropertyDecimalOverIntergerPrecision = 'std_PropertyDecimalOverIntergerPrecision';
    static readonly PropertyDecimalOverIntergerPrecision_TAG_NomeCampo = 'std_NomeCampo';
    static readonly PropertyDecimalOverIntergerPrecision_TAG_MassimoNumeroInteri = 'std_MassimoNumeroInteri';
    // #endregion Decimal Format Validator

    // #region Http Message Codes
    static readonly UnauthorizedHttpStatusCode = 'std_UnauthorizedHttpStatusCode';
    static readonly NotFoundHttpStatusCode = 'std_NotFoundHttpStatusCode';
    // #endregion Http Message Codes

    // #region Commands
    static readonly CommandNotAllowed = 'std_CommandNotAllowed';
    // #endregion Commands

    // #region Autocomplete and decode
    static readonly ErrorCodeNotFound = 'std_ErrorCodeNotFound';
    static readonly ErrorCodeNotFound_TAG_NomeCampo = 'std_NomeCampo';
    // #endregion Autocomplete and decode

    // #region Expandable
    static readonly ExpandableShowMoreDescription = 'std_ExpandableShowMore_Description';
    static readonly ExpandableShowLessDescription = 'std_ExpandableShowLess_Description';
    static readonly ExpandableOpenPanelDescription = 'std_ExpandableOpenPanel_Description';
    static readonly ExpandableClosePanelDescription = 'std_ExpandableClosePanel_Description';
    // #endregion Autocomplete and decode

    // #region Toolbar
    static readonly MoreOptionsMenuCommand = 'std_CMD_MoreOptions';
    static readonly MobileMenuCommand = 'std_CMD_MobileMenu';
    static readonly CMD_F8Return = 'std_CMD_F8Return';
    static readonly CMD_HistoryBack = 'std_CMD_HistoryBack';

    static readonly RelatedCommandGroupsDisplayName = 'std_CommandGroups_Related_DisplayName';
    static readonly RelatedCommandGroupsName = 'std_CommandGroups_Related_Name';

    static readonly ReportCommandGroupsDisplayName = 'std_CommandGroups_Report_DisplayName';
    static readonly ReportCommandGroupsName = 'std_CommandGroups_Report_Name';

    static readonly ActionsCommandGroupsDisplayName = 'std_CommandGroups_Actions_DisplayName';
    static readonly ActionsCommandGroupsName = 'std_CommandGroups_Actions_Name';

    static readonly HomeCommandGroupsDisplayName = 'std_CommandGroups_Home_DisplayName';
    static readonly HomeCommandGroupsName = 'std_CommandGroups_Home_Name';

    static readonly SidebarCommandGroupsDisplayName = 'std_CommandGroups_Sidebar_DisplayName';
    static readonly SidebarCommandGroupsName = 'std_CommandGroups_Sidebar_Name';

    static readonly ReturnCommandGroupsDisplayName = 'std_CommandGroups_Return_DisplayName';
    static readonly ReturnCommandGroupsName = 'std_CommandGroups_Return_Name';

    static readonly HistoryBackButtonCommandGroupsName = 'std_CommandGroups_HistoryBackButton_Name';
    static readonly HistoryBackButtonCommandGroupsDisplayName = 'std_CommandGroups_HistoryBackButton_DisplayName';
    // #endregion Toolbar

    // #region Dialog
    static readonly Information = 'std_Information';
    static readonly Warning = 'std_Warning';
    static readonly Error = 'std_Error';
    static readonly UnsavedData = 'std_UnsavedData';
    static readonly Remove = 'std_Remove';
    // #endregion Dialog

    // #region Zoom
    static readonly ZoomFindException = 'std_ZoomFindException';
    static readonly Zoom = 'std_Zoom';
    static readonly ZoomParameters_PropertyName = 'std_ZoomParameters_PropertyName';
    static readonly ZoomParameters_Operator = 'std_ZoomParameters_Operator';
    static readonly ZoomParameters_FilterValue = 'std_ZoomParameters_FilterValue';
    static readonly ZoomList_AddFavorite = 'std_ZoomList_AddFavorite';
    static readonly ZoomList_RemoveFavorite = 'std_ZoomList_RemoveFavorite';
    // #endregion Zoom

    // #region Snapshot
    static readonly VersionedTitle = 'std_VersionedTitle';
    static readonly VersionedTitle_TAG_Data = 'std_Data';
    // #endregion Snapshot

    static readonly AboutWindowDisplayName = 'std_AboutWindow_DisplayName';
    static readonly AboutCommandGroupsName = 'std_CommandGroups_About_Name';
    static readonly AboutCommandGroupsDisplayName = 'std_CommandGroups_About_DisplayName';

    static readonly CustomizeFieldsWindowDisplayName = 'std_CustomizeFieldsWindow_DisplayName';
    static readonly CustomizeFieldsCommandGroupsName = 'std_CommandGroups_CustomizeFields_Name';
    static readonly CustomizeFieldsCommandGroupsDisplayName = 'std_CommandGroups_CustomizeFields_DisplayName';

    static readonly SecurityWindowDisplayName = 'std_SecurityWindow_DisplayName';
    static readonly SecurityCommandGroupsName = 'std_CommandGroups_Security_Name';
    static readonly SecurityCommandGroupsDisplayName = 'std_CommandGroups_Security_DisplayName';

    static readonly FilterOperators_None = 'std_FilterOperators_None';
    static readonly FilterOperators_Equals = 'std_FilterOperators_Equals';
    static readonly FilterOperators_NotEquals = 'std_FilterOperators_NotEquals';
    static readonly FilterOperators_GreaterThan = 'std_FilterOperators_GreaterThan';
    static readonly FilterOperators_GreaterOrEqualThan = 'std_FilterOperators_GreaterOrEqualThan';
    static readonly FilterOperators_LessThan = 'std_FilterOperators_LessThan';
    static readonly FilterOperators_LessOrEqualThan = 'std_FilterOperators_LessOrEqualThan';
    static readonly FilterOperators_IsNull = 'std_FilterOperators_IsNull';
    static readonly FilterOperators_IsNotNull = 'std_FilterOperators_IsNotNull';
    static readonly FilterOperators_Contains = 'std_FilterOperators_Contains';
    static readonly FilterOperators_StartWith = 'std_FilterOperators_StartWith';
    static readonly FilterOperators_EndWith = 'std_FilterOperators_EndWith';
    static readonly FilterOperators_Between = 'std_FilterOperators_Between';
    static readonly FilterOperators_In = 'std_FilterOperators_In';

}
