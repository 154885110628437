import { APP_INITIALIZER, ErrorHandler, ModuleWithProviders, NgModule, NgZone, Provider } from '@angular/core';
import { ComponentsModule } from '../components/component.module';
import { ConfigurationLoader, UtilityModule, ClientConfigurationLoader, ConfigurationManager, LogService, OnlineService, LocalstorageHelper, TabSocket, CustomErrorHandlerService } from '@nts/std/src/lib/utility';
import { AuthService } from '../auth/auth.service';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHttpInterceptor } from '../auth/auth-http-interceptor.service';
import { CurrentSessionService } from '../auth/current-session.service';
import { WebApiServiceAgent } from '../api-clients/web-api-service-agent';
import { EndPointResolver } from '../api-clients/end-point-resolver';
import { ResponseCacheService } from '../responses/response-cache.service';
import { FrameworkServiceApiClient } from '../api-clients/framework-service-api-client';
import { TokenService } from '../auth/token.service';
import { LOG_DECYCLE_LIB, LOG_LEVEL } from '@nts/std/src/lib/utility';
import { JsonNetDecycle } from '../serialization/json-net-decycle';
import { EnvironmentConfiguration } from '@nts/std/src/lib/environments';
import { TranslationLoader } from '../resources/translation-loader.factory';
import { VersionLoader } from '../resources/version-loader.factory';
import { firstValueFrom } from 'rxjs';
import { RESPONSE_CACHE_WEBWORKER_FACTORY } from '../token/response-cache.token';
import { Driver, NgForage, NgForageConfig } from 'ngforage';
import { TelemetryService } from '@nts/std/src/lib/telemetry';
import { ErrorNotificationService } from '@nts/std/src/lib/utility';
import { provideSvgIconsConfig } from '@ngneat/svg-icon';
import { coreIcons } from '../icons/core';
import { stdUnknownIcon } from '../icons/core/unknown';
import { CurrentRouteService } from '../routing/current-route.service';
import { Router } from '@angular/router';

export function initializerFactoryFunction(
  translationLoader: TranslationLoader,
  http: HttpClient,
  env: EnvironmentConfiguration,
  versionLoader: VersionLoader,
  configurationLoader: ConfigurationLoader,
  clientConfigurationLoader: ClientConfigurationLoader,
  frameworkServiceApiClient: FrameworkServiceApiClient,
  onlineService: OnlineService,
  zone: NgZone,
  authService: AuthService,
  tokenService: TokenService, // Necessario per la gestione dei token
  ngfConfig: NgForageConfig,
  ngf: NgForage,
  telemetryService: TelemetryService
) {
  // Do initing of services that is required before app loads
  // NOTE: this factory needs to return a function (that then returns a promise)
  return async () => {

    ngfConfig.configure({
      name: env.appTitle,
      driver: [ // defaults to indexedDB -> webSQL -> localStorage
        Driver.INDEXED_DB,
        Driver.LOCAL_STORAGE
      ]
    });

    const inIframe = window.location.href.includes('iframe=true') && window.self != window.top;
    LogService.debug(`${window.location.href} - inIframe: ${inIframe}`);
    LocalstorageHelper.init(zone, inIframe, ngf, env);
    LogService.debug(`LocalstorageHelper inizializzato`, {
      inIframe,
      env,
      ngf
    });



      await firstValueFrom(clientConfigurationLoader.getConfiguration());
      await firstValueFrom(configurationLoader.getConfiguration());
      env.baseAppUrl = (
        ConfigurationManager?.Current?.config?.baseAppUrl === '' ||
        ConfigurationManager?.Current?.config?.baseAppUrl == null
      ) ?
        env.baseAppUrl :
        ConfigurationManager?.Current?.config?.baseAppUrl;

      // Remove slash and backslash at the end of the string
      env.baseAppUrl = env.baseAppUrl.endsWith('/') ? env.baseAppUrl.slice(0, -1) : env.baseAppUrl;
      env.baseAppUrl = env.baseAppUrl.endsWith('\\') ? env.baseAppUrl.slice(0, -1) : env.baseAppUrl;

      env.authenticationAppUrl = (
        ConfigurationManager?.Current?.config?.authenticationAppUrl === '' ||
        ConfigurationManager?.Current?.config?.authenticationAppUrl == null
      ) ?
        env.authenticationAppUrl :
        ConfigurationManager?.Current?.config?.authenticationAppUrl;

      // Remove slash and backslash at the end of the string
      env.authenticationAppUrl = env.authenticationAppUrl.endsWith('/') ? env.authenticationAppUrl.slice(0, -1) : env.authenticationAppUrl;
      env.authenticationAppUrl = env.authenticationAppUrl.endsWith('\\') ? env.authenticationAppUrl.slice(0, -1) : env.authenticationAppUrl;

      await firstValueFrom(translationLoader.getTranslation(navigator.language));
      await firstValueFrom(versionLoader.getVersion());

      // Clean old cache
      if ('caches' in window) {

        const deleteCache = async key => {
          await caches.delete(key)
        }

        const deleteAllCaches = async () => {
          const keyList = await caches.keys()
          const cachesToDelete = keyList;
          LogService.log('Cancello cache di workers')
          await Promise.all(cachesToDelete.map(deleteCache));
        }
        // "ngsw:/:8950aab197ca77a01a4717c10e1197e9e4f330ea:assets:app:cache"
        // "ngsw:/:e21b140ad4a1e5370bbb03ec9a2b2c9c51fe26a7:assets:app:cache"
        const versionList = (await caches.keys()).filter(key => key.indexOf('assets:app:cache') > -1);
        if (versionList?.length > 1) {
          LogService.log('Workers trovati', versionList)
          deleteAllCaches();
        }
      }

      // let cookiePreferences = new CookiePreferencesDto();
      // try {
      //   await firstValueFrom(
      //     frameworkServiceApiClient.getCookiePreferences().pipe(
      //       map(async (cookiePreferencesResponse) => {
      //         if (cookiePreferencesResponse?.operationSuccedeed) {
      //           cookiePreferences = cookiePreferencesResponse.result;
      //         }
      //         if (onlineService.isOnline === true) {
      //           if (cookiePreferences.essentialCookies) {

      //             const tenantProfile = await authService.getTenantProfile()

      //             if (!telemetryService.initialized) {
      //               let workerVersions = 'N/A';
      //               if ('caches' in window) {
      //                   const workerVersionList = (await caches.keys()).filter(key => key.indexOf('assets:app:cache') > -1);
      //                   if (workerVersionList?.length > 0) {
      //                       workerVersions = workerVersionList.join(' - ');
      //                   }
      //               }

      //               telemetryService.init(
      //                 ConfigurationManager?.Current?.config?.appInsights?.connectionString,
      //                 ConfigurationManager?.Current?.config?.appInsights?.enableAutoRouteTracking,
      //                 {
      //                   ...VersionManager.Current.version,
      //                   workerVersions
      //                 },
      //                 cookiePreferences.analyticsCookies,
      //                 tenantProfile.businessName
      //               );
      //             } else {
      //               telemetryService.setCanUseAuthenticatedUserContext = cookiePreferences.analyticsCookies;
      //             }

      //             telemetryService.setAuthenticatedUserContext(
      //               await authService.getUserProfile(),
      //               tenantProfile
      //             );

      //             if (!cookiePreferences.analyticsCookies) {
      //               telemetryService.clearAuthenticatedUserContext()
      //             }
      //           } else {
      //             telemetryService.clearAuthenticatedUserContext()
      //           }
      //         }
      //         return true;
      //       })
      //     )
      //   )
      // } catch (err) {
      //   LogService.warn(err);
      // }

  };
}
@NgModule({
  declarations: [
  ],
  providers: [
  ],
  imports: [
    ComponentsModule,
  ],
  exports: [
    ComponentsModule,
  ]
})
export class StdModule {
  static forRoot(): ModuleWithProviders<StdModule> {
    return {
      ngModule: StdModule,
      providers: [
        ...UtilityModule.getRootProviders(),
        ...ComponentsModule.getRootProviders(),

        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthHttpInterceptor,
          multi: true,
          deps: [AuthService, CurrentRouteService]
        },
      ]
    };
  }

  static forChild(): ModuleWithProviders<StdModule>{
    return {
      ngModule: StdModule,
      providers: []
    };
  }

  static getRootProviders(): Provider[] {
    return [
      provideSvgIconsConfig({
        missingIconFallback: stdUnknownIcon,
        sizes: {
            md: '24px',
        },
        defaultSize: 'md',
        icons: [...coreIcons]
      }),
      ...UtilityModule.getRootProviders(),
      ...ComponentsModule.getRootProviders(),
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthHttpInterceptor,
        multi: true,
        deps: [AuthService, CurrentRouteService]
      },
      {
        provide: EndPointResolver,
        deps: [EnvironmentConfiguration]
      },
      {
        provide: RESPONSE_CACHE_WEBWORKER_FACTORY,
        useValue: function (): Worker {
            return new Worker(new URL('projects/std/src/lib/worker/response-cache/response-cache.worker', import.meta.url), {
                name: 'response-cache.worker',
                type: 'module',
            });
        },
      },
      {
        provide: ResponseCacheService,
      },
      // {
      //   provide: SERIALIZATION_WEBWORKER_FACTORY,
      //   useValue: function (): Worker {
      //       return new Worker(new URL('projects/std/src/lib/worker/serialization/serialization.worker', import.meta.url), {
      //           name: 'serialization.worker',
      //           type: 'module',
      //       });
      //   },
      // },
      {
        provide: WebApiServiceAgent,
        deps: [HttpClient, EndPointResolver, ResponseCacheService, OnlineService]
      },
      {
        provide: CurrentSessionService,
        deps: [AuthService]
      },
      {
        provide: FrameworkServiceApiClient,
        deps: [WebApiServiceAgent]
      },
      {
        provide: TokenService,
        deps: [AuthService, FrameworkServiceApiClient]
      },
      // Necessari per il logger
      {
        provide: LOG_DECYCLE_LIB, useValue: JsonNetDecycle
      },
      {
        provide: LOG_LEVEL,
        useFactory: (env: EnvironmentConfiguration) => env.logLevel,
        deps: [EnvironmentConfiguration]
      },
      ErrorNotificationService,
      // {
      //     provide: ErrorHandler,
      //     useClass: CustomErrorHandlerService,
      //     deps: [
      //         TelemetryService,
      //         EnvironmentConfiguration,
      //         ErrorNotificationService
      //     ]
      // },

      // NgForage Deps
      // {
      //   provide: DEFAULT_CONFIG,
      //   useValue: {
      //     name: 'NTS',
      //     driver: [ // defaults to indexedDB -> webSQL -> localStorage
      //       Driver.INDEXED_DB,
      //       Driver.LOCAL_STORAGE
      //     ]
      //   } as NgForageOptions
      // },

      // Initializer
      {
        provide: APP_INITIALIZER,
        useFactory: initializerFactoryFunction,
        deps: [
          TranslationLoader,
          HttpClient,
          EnvironmentConfiguration,
          VersionLoader,
          ConfigurationLoader,
          ClientConfigurationLoader,
          FrameworkServiceApiClient,
          OnlineService,
          NgZone,
          AuthService,
          TokenService,
          NgForageConfig,
          NgForage,
          TelemetryService
        ],
        multi: true
      }
    ];
  }
}
