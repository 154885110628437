import { ToastMessageService } from './layout/toast-message/toast-message.service';
import { NgModule, Provider } from '@angular/core';
import { EntryPageComponent } from './entry-page/entry-page.component';
import moment from 'moment';
import { MessagesAreaContainerComponent } from './message-area-container/messages-area-container.component';
import { MasterAreaContainerComponent } from './master-area-container/master-area-container.component';
import { NotFoundComponent } from './errors/not-found/not-found.component';
import { ModelContainerComponent } from './model-container/model-container.component';
import { ComponentLocator } from './component-locator';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { ModalService } from '../view-models/modal/modal.service';
import { ModalComponentService } from './modal/modal-component.service';
import { PopupViewModel } from '../view-models/modal/popup-view-model';
import { PopupContainerComponent } from './popup/popup-container.component';
import { ExternalModalViewModel } from '../view-models/modal/external-modal-view-model';
import { ExternalModalContainerComponent } from './external-modal-container/external-modal-container.component';
import { IframeComponent } from './iframe/iframe.component';
import { ZoomOrchestratorViewModel } from '../view-models/zoom/zoom-orchestrator-view-model';
import { ZoomContainerComponent } from './zoom/zoom-container/zoom-container.component';
import { ZoomComponentModule } from './zoom/zoom-component.module';
import { WingContainerComponent } from './wing-container/wing-container.component';
import { SnapShotListContainerComponent } from './snap-shot-list-container/snap-shot-list-container.component';
import { UserImageComponent } from './user-image/user-image.component';
import { ForbiddenComponent } from './errors/forbidden/forbidden.component';
import { InternalServerErrorComponent } from './errors/internal-server-error/internal-server-error.component';
import { NavigationPanelComponent } from './navigation-panel/navigation-panel.component';
import { NumeralService } from '@nts/std/src/lib/utility';
import { UnauthorizedRequestComponent } from './errors/unauthorized-request/unauthorized-request.component';
import { DynamicPresentationComponent } from './dynamic-presentation/dynamic-presentation.component';
import { OfflineModeComponent } from './errors/offline-mode/offline-mode.component';
import { EnterpriseSelectorViewModel } from '../view-models/modal/enterprise-selector-modal-view-model';
import { EnterpriseSelectorContainerComponent } from './enterprise-selector/enterprise-selector-container.component';
import { AddZoomModalViewModel } from '../view-models/zoom/zoom-list-view-model/modals/add-zoom-modal-view-model';
import { AddZoomModalContainerComponent } from './zoom/zoom-list/modals/add-zoom/add-zoom-modal-container.component';
import { EditZoomModalViewModel } from '../view-models/zoom/zoom-list-view-model/modals/edit-zoom-modal-view-model';
import { EditZoomModalContainerComponent } from './zoom/zoom-list/modals/edit-zoom/edit-zoom-modal-container.component';
import { PinIdentityToDashboardModalContainerComponent } from './pin-identity-to-dashboard-modal-container/pin-identity-to-dashboard-modal-container.component';
import { PinIdentityToDashboardModalViewModel } from '../view-models/modal/pin-identity-to-dashboard-modal-view-model';
import { LayoutModule } from './layout/layout.module';
import { ModalContainerComponent } from './modal/modal-container.component';
import { NTSTranslatePipe } from './pipe/nts-translation-pipe';
import { NTSFilterPipe } from './pipe/nts-filter-pipe';
import { SharedModule } from './shared/shared.module';
import { MatDialogModule } from '@angular/material/dialog';
import { ControlsComponentModule } from './controls/controls-component.module';
import { ErrorsComponentModule } from './errors/errors-component.module';
import { NumeralLoaderService } from '@nts/std/src/lib/utility';

@NgModule({
    imports: [
        ControlsComponentModule,
        DynamicPresentationComponent,
        EnterpriseSelectorContainerComponent,
        EntryPageComponent,
        ErrorsComponentModule,
        ExternalModalContainerComponent,
        IframeComponent,
        LayoutModule,
        MasterAreaContainerComponent,
        MessagesAreaContainerComponent,
        ModalContainerComponent,
        ModelContainerComponent,
        NavigationPanelComponent,
        PinIdentityToDashboardModalContainerComponent,
        NTSFilterPipe,
        NTSTranslatePipe,
        PopupContainerComponent,
        SharedModule,
        SnapShotListContainerComponent,
        UserImageComponent,
        WingContainerComponent,
        ZoomComponentModule,

        MatDialogModule,
    ],
    exports: [
        ControlsComponentModule,
        DynamicPresentationComponent,
        EnterpriseSelectorContainerComponent,
        EntryPageComponent,
        ErrorsComponentModule,
        ExternalModalContainerComponent,
        IframeComponent,
        LayoutModule,
        MasterAreaContainerComponent,
        MessagesAreaContainerComponent,
        ModalContainerComponent,
        ModelContainerComponent,
        NavigationPanelComponent,
        PinIdentityToDashboardModalContainerComponent,
        NTSFilterPipe,
        NTSTranslatePipe,
        PopupContainerComponent,
        SharedModule,
        SnapShotListContainerComponent,
        UserImageComponent,
        WingContainerComponent,
        ZoomComponentModule,

        MatDialogModule,
    ],
    providers: [

    ]
})
export class ComponentsModule {

    constructor(
        private componentLocator: ComponentLocator,

        // Necessario per caricare numeral service la prima volta
        numeralLoaderService: NumeralLoaderService,
        primeNGConfig: PrimeNGConfig
    ) {

        this.registerDialogs();

        numeralLoaderService.init();

        // load correct culture file
        const language = navigator.language;

        try {
            if (NumeralService.Current.locales[language.toLowerCase()] == null) {
                // Fallback su italiano
                NumeralService.Current.locale('it');
                moment.locale('it');
            } else {
                NumeralService.Current.locale(language.toLowerCase());
                // il locale di default è gia inglese
                if (!(language === 'en' || language === 'en-US')) {
                    moment.locale(language);
                }
            }

        } catch (e) {

            // Fallback su italiano in caso di errrore
            NumeralService.Current.locale('it');
            moment.locale('it');
        }
        NumeralService.Current.defaultFormat('0,.00');

        primeNGConfig.setTranslation({
            dayNames: moment.localeData().weekdays(),
            dayNamesShort: moment.localeData().weekdaysShort(),
            dayNamesMin: moment.localeData().weekdaysMin(),
            monthNames: moment.localeData().months(),
            monthNamesShort: moment.localeData().monthsShort(),
            clear: '',
            today: '',
            firstDayOfWeek:  moment.localeData().firstDayOfWeek()
        })
    }

    static getRootProviders(): Provider[] {
        return [
            MessageService,
            ToastMessageService,
            PrimeNGConfig,
            {
                provide: ModalService,
                useClass: ModalComponentService,
            },
            ComponentLocator
        ];
    }

    private registerDialogs() {
        this.componentLocator.registerViewModelComponentAssociation(
            PopupViewModel, PopupContainerComponent);

        this.componentLocator.registerViewModelComponentAssociation(
            ExternalModalViewModel, ExternalModalContainerComponent);

        this.componentLocator.registerViewModelComponentAssociation(
            ZoomOrchestratorViewModel, ZoomContainerComponent);

        this.componentLocator.registerViewModelComponentAssociation(
            EnterpriseSelectorViewModel, EnterpriseSelectorContainerComponent);

        this.componentLocator.registerViewModelComponentAssociation(
            AddZoomModalViewModel, AddZoomModalContainerComponent);

        this.componentLocator.registerViewModelComponentAssociation(
            EditZoomModalViewModel, EditZoomModalContainerComponent);

        this.componentLocator.registerViewModelComponentAssociation(
            PinIdentityToDashboardModalViewModel, PinIdentityToDashboardModalContainerComponent);
    }
}
