<mat-toolbar>
    <!-- Begin Menu Toggle -->
    <div class="toolbar-item" *ngIf="!hideToggle">
        <div class="toggle-container">
            <nts-text-button (onClicked)="onToggleMenu()" [icon]="'menu-align'"></nts-text-button>
        </div>
    </div>
    <!-- End Menu Toggle -->

    <!-- Begin Brand Image -->
    <div class="toolbar-item">
        <a class="header-brand">
            <img src="assets/std/images/logo.png" alt="NTS Cloud" class="header-brand-img" (click)="onClickLogo.emit()">
            <div class="header-brand-name"></div>
        </a>
    </div>
    <!-- End Brand Image -->

    <span class="toolbar-spacer"></span>

    <!-- Begin Offline Icon -->
    <div class="toolbar-item" *ngIf="!(isOnline$ | async)">
        <svg-icon popperApplyClass="alert" popper="Modalità offline" class="connection-issue" key="wifi-off"></svg-icon>
    </div>
    <!-- End Offline Icon -->

    <!-- Begin Tenant Button -->
    <div class="toolbar-item" #tenantMenuToggle #tenantMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="tenantMenu" (menuOpened)="tenantMenuOpened()">
        <div *ngIf="!tenantRefreshing">
            <nts-user-image *ngIf="tenant?.businessName; else noTenantIcon" [email]="tenant?.email"
                [base64]="tenant?.base64Image">
            </nts-user-image>
            <ng-template #noTenantIcon>
                <div class="no-tenant-icon">
                    <span class="ms-Icon ms-Icon--UserWarning" role="presentation"
                        style="display: flex; flex-direction: column; font-size: 16px;"></span>
                </div>
            </ng-template>
        </div>
        <div *ngIf="tenantRefreshing">
            <nts-spinner [isVisible]="true"></nts-spinner>
        </div>
    </div>
    <mat-menu #tenantMenu="matMenu" [hasBackdrop]="false" xPosition="after">
        <ng-template matMenuContent>
           <div class="detail-container">

                <div class="content-container" (click)="$event.stopPropagation()">
                    <div class="image-contaier">
                        <nts-user-image *ngIf="tenant?.businessName; else noTenantProfile"
                            [email]="tenant?.email"
                            [base64]="tenant?.base64Image"
                            [size]="60">
                        </nts-user-image>
                        <ng-template #noTenantProfile>
                            <div class="no-tenant-profile">
                                <span class="ms-Icon ms-Icon--UserWarning" role="presentation"
                                    style="display: flex; flex-direction: column; font-size: 16px;"></span>
                            </div>
                        </ng-template>
                    </div>
                    <div class="info-contaier">
                        <div class="info-line business-name">
                            {{ tenantRefreshing ? ('ChangeTenant_TenantCheckingMessage' | NTSTranslate) : ( tenant ?
                                    tenant?.businessName : ('ChangeTenant_NotSetMessage'| NTSTranslate)) }}
                        </div>
                        <div class="info-line additional-info">
                            {{ tenant ? (tenant?.vatNumber + '-' + getTenantTypeDescription(tenant?.tenantType))
                                    : '' }}
                        </div>
                    </div>
                </div>
                <div class="actions-container">
                    <nts-ribbon-button (onClicked)="changeTenant()" [label]="(tenantRefreshing || tenant?.businessName) ? ('ChangeTenant_ChangeTenant_DisplayName' | NTSTranslate) : ('ChangeTenant_SetTenant_DisplayName' | NTSTranslate)"></nts-ribbon-button>
                    <nts-ribbon-button (onClicked)="logOut()" icon="log-out"></nts-ribbon-button>
                </div>
           </div>
        </ng-template>
    </mat-menu>
    <!-- End Tenant Button -->

    <!-- Begin User Button -->
    <div class="toolbar-item" #userMenuToggle #userMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="userMenu" (menuOpened)="userMenuOpened()">
        <nts-user-image [email]="user?.loginInformation?.email" [base64]="user?.base64Image">
        </nts-user-image>
    </div>
    <mat-menu #userMenu="matMenu" [hasBackdrop]="false" xPosition="after">
        <ng-template matMenuContent>
           <div class="detail-container">

                <div class="content-container" (click)="$event.stopPropagation()">
                    <div class="image-contaier">
                        <nts-user-image
                            [email]="user?.loginInformation?.email"
                            [size]="60"
                            [base64]="user?.base64Image">
                        </nts-user-image>
                    </div>
                    <div class="info-contaier">
                        <div class="info-line business-name">
                            {{ user?.name }} {{ user?.lastName }}
                        </div>
                        <div class="info-line additional-info">
                            {{ user?.loginInformation?.email }}
                        </div>
                    </div>
                </div>
                <div class="actions-container">
                    <nts-ribbon-button *ngIf="isOnline$ | async" (onClicked)="openProfile()" [label]="'UserProfile_ViewProfile_DisplayName'|NTSTranslate"></nts-ribbon-button>
                    <nts-ribbon-button (onClicked)="logOut()" icon="log-out"></nts-ribbon-button>
                </div>
           </div>
        </ng-template>
    </mat-menu>
    <!-- End User Button -->

    <!-- Begin Enterprise Section -->
    <div class="toolbar-item enterprise-item" #enterpriseMenuToggle #enterpriseMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="enterpriseMenu" *ngIf="enterpriseList?.enterprises?.length > 0" (menuOpened)="enterpriseMenuOpened()">
        <div class="enterprise-icon" [style.backgroundColor]="currentCompanyColor">
            <svg-icon key="home" fontSize="29px"></svg-icon>
        </div>
        <div class="enterprise-container">
            <div class="enterprise-description">{{currentEnterprise?.description ? currentEnterprise?.description: ''}}</div>
            <div class="company-description">{{currentCompany?.description ? currentCompany?.description: ''}}</div>
        </div>
    </div>
    <mat-menu #enterpriseMenu="matMenu" [hasBackdrop]="false" xPosition="after" *ngIf="isEnterpriseBarrierRequired">
        <ng-template matMenuContent>
           <div class="enterprise-detail-container">
                <div class="enterprise-content-container" (click)="$event.stopPropagation()">
                    <div class="enterprise-content-item">
                        {{'ChangeEnterprise_SelectEnterprise_DisplayName'|NTSTranslate}}
                        <nts-base-enum-text-box
                            [options]="enterpriseSelectorViewModel.enterpriseList"
                            [(value)]="enterpriseSelectorViewModel.selectedEnterprise"
                            (valueChange)="enterpriseSelectorViewModel.onEnterpriseChange($event)"></nts-base-enum-text-box>
                    </div>

                    <div class="enterprise-content-item">
                        {{'ChangeEnterprise_SelectCompany_DisplayName'|NTSTranslate}}
                        <nts-base-enum-text-box
                            [isDisabled]="enterpriseSelectorViewModel.filteredCompanyList?.length === 0"
                            [options]="enterpriseSelectorViewModel.filteredCompanyList"
                            [(value)]="enterpriseSelectorViewModel.selectedCompany"
                            (valueChange)="enterpriseSelectorViewModel.onCompanyChange($event)"></nts-base-enum-text-box>
                    </div>

                </div>
                <div class="actions-container">
                    <nts-ribbon-button [label]="'ChangeEnterprise_Cancel_DisplayName'|NTSTranslate" (onClicked)="closeAll()"></nts-ribbon-button>
                    <!-- <nts-ribbon-button (onClicked)="logOut()" icon="log-out"></nts-ribbon-button> -->
                    <nts-filled-button icon="check" [label]="'ChangeEnterprise_Confirm_DisplayName'|NTSTranslate" [type]="filledButtonType.Info" (onClicked)="changeEnterpriseAndCompany()"></nts-filled-button>
                </div>
           </div>
        </ng-template>
    </mat-menu>
    <!-- End Enterprise Section -->
</mat-toolbar>
