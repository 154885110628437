import { UIResultCommandInterface } from '../commands/ui-result-command.interface';
import { CommandFactory } from '../commands/command-factory';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { EnumItemInterface } from '../base-type/enum-property-view-model';
import { EnterprisesListDto } from '../../auth/dto/enterprise-list.dto';
import { EnterpriseInformationDto } from '../../auth/dto/enterprise-information.dto';
import { MessageResourceManager } from '../../resources/message-resource-manager';

export class EnterpriseSelectorViewModel {

    cancelAction: Subject<void> = new Subject();
    selectCommand: UIResultCommandInterface<{
        selectedEnterprise: EnumItemInterface<number>,
        selectedCompany: EnumItemInterface<number>
    }, any>;

    enterpriseListDto: EnterprisesListDto;
    enterpriseList: EnumItemInterface<number>[] = [];
    selectedEnterprise: EnumItemInterface<number> = null;
    filteredCompanyList: EnumItemInterface<number>[] = [];
    selectedCompany: EnumItemInterface<number> = null;

    async initialize(): Promise<void> {
        if (this.selectedEnterprise) {
            this.onEnterpriseChange(this.selectedEnterprise)
        }
    }

    onEnterpriseChange(item: EnumItemInterface<number>) {
        const found = this.enterpriseListDto.enterprises.find((e: EnterpriseInformationDto) => e.enterpriseId === item.key);

        if (found?.companies?.length > 0) {
            this.filteredCompanyList = found.companies.map((c) => {
                const companyEnum: EnumItemInterface<number> = {
                  key: c.companyId,
                  description: c.name,
                  isDefault: c.isDefault,
                  descriptionExt: c.color
                }
                return companyEnum;
            })
        }

        if (this.filteredCompanyList.length > 0 && !this.selectedCompany) {
            this.selectedCompany = this.filteredCompanyList.find((c) => c.isDefault === true) ?? this.filteredCompanyList[0];
        } else if (this.filteredCompanyList.length > 0 && this.selectedCompany) {
            const found = this.filteredCompanyList.find((c) => c.key === this.selectedCompany.key);
            if (!found) {
                this.selectedCompany = this.filteredCompanyList.find((c) => c.isDefault === true) ?? this.filteredCompanyList[0];
            }
        }
    }

    onCompanyChange(item: EnumItemInterface<number>) {
    }

    constructor() {
        this.setupCommands();
    }

    private setupCommands() {

        this.selectCommand = CommandFactory.createResultUICommand(
            async (returnedJsonIdentity: string) => {
                return {
                    selectedEnterprise: this.selectedEnterprise,
                    selectedCompany: this.selectedCompany
                };
            },
            () => of(true)
        );

        this.selectCommand.closeModal = true;
        this.selectCommand.displayName = MessageResourceManager.Current.getMessage('EnterpriseSelector_SelectCommand_DisplayName');
        this.selectCommand.isDefault = true;
        this.selectCommand.isVisible$ = new BehaviorSubject<boolean>(true);
    }
}
