import { LogService } from '@nts/std/src/lib/utility';
import { firstValueFrom, Observable, of } from 'rxjs';
import { BaseScheduleLongOpApiClient } from '../../api-clients/long-op/base-schedule-long-op-api-client';
import { BaseIdentity } from '../../domain-models/base-identity';
import { BaseLongOpModel } from '../../domain-models/base-long-op-model';
import { CoreModel } from '../../domain-models/core-model';
import { MessageResourceManager } from '../../resources/message-resource-manager';
import { ServiceResponse } from '../../responses/service-response';
import { RoutingService } from '../../routing/routing.service';
import { MsgClearMode } from '../core-orchestrator-view-model';
import { InternalViewModel } from '../internal-view-model';
import { ToolBarViewModelInterface } from '../tool-bar-view-model.interface';
import { BaseScheduleLongOpOrchestratorViewModelInterface } from './base-schedule-long-op-orchestrator-view-model.interface';
import { BaseScheduleLongOpToolBarViewModel } from './base-schedule-long-op-tool-bar-view-model';
import { LongOpOrchestratorViewModel } from './long-op-orchestrator-view-model';
import { LongOpRootViewModel } from './long-op-root-view-model';

export class BaseScheduleLongOpOrchestratorViewModel<
    TViewModel extends LongOpRootViewModel<TModel, TIdentity, TParams, TResult, TParamsModel, TResultModel>,
    TApiClient extends BaseScheduleLongOpApiClient<TModel, TIdentity, TParamsModel, TResultModel>,
    TModel extends BaseLongOpModel<TIdentity, TParamsModel, TResultModel>,
    TIdentity extends BaseIdentity,
    TParams extends InternalViewModel<TParamsModel, TIdentity>,
    TResult extends InternalViewModel<TResultModel, TIdentity>,
    TParamsModel extends CoreModel<TIdentity>,
    TResultModel extends CoreModel<TIdentity>>
    extends LongOpOrchestratorViewModel<TViewModel, TApiClient, TModel, TIdentity, TParams, TResult, TParamsModel, TResultModel>
    implements BaseScheduleLongOpOrchestratorViewModelInterface {

    override getToolBarMenu(): ToolBarViewModelInterface {
        return new BaseScheduleLongOpToolBarViewModel(this);
    }

    async schedule(): Promise<void> {
        if (this.actionInProgress == false) {
            this.eventDispatcher.onActionInProgress.next(true);
        }
        const responseDraftSchedule = await firstValueFrom(this.apiClient.createDraftSchedule(this.rootViewModel.getDomainModel()));

        let url = '';
        let draftId = '';

        if (responseDraftSchedule.operationSuccedeed && responseDraftSchedule.result?.draftId?.length > 0 && responseDraftSchedule.result?.schedulePlanUIInfo?.fullAddress?.length > 0) {
            draftId = responseDraftSchedule.result?.draftId;
            url = responseDraftSchedule.result.schedulePlanUIInfo.fullAddress;
        } else {
            this.showFromResponse(responseDraftSchedule, MsgClearMode.ClearAllMessages);
        }

        if (url?.length > 0 && draftId?.length > 0) {
            this.eventDispatcher.externalModalExecuted.emit();

            const additionalQueryParams = new URLSearchParams();
            additionalQueryParams.append('draftId', draftId);

            const result = await this.showExternalModalWithResultAsync<string>(
                url.toLowerCase(),
                null,
                additionalQueryParams,
                MessageResourceManager.Current.getMessage('std_ScheduleWindow_DisplayName'),
            );
            if (!result.cancel) {
                const response = RoutingService.decodeObject(result.result, ServiceResponse)
                this.toastMessageService.showToastsFromResponse(response);
            }
            this.eventDispatcher.externalModalReturned.emit();
        } else {
            LogService.warn(MessageResourceManager.Current.getMessage('std_UrlOrJsonIdentityNotValid'))
        }

        this.eventDispatcher.onActionInProgress.next(false);
    }

    canSchedule(): Observable<boolean> {
        return of(true);
    }
    isVisibleSchedule(): Observable<boolean> {
        return of(true);
    }
}
